<template>
  <div>
    <section id="clientes" class="top" style="padding-top:70px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/pepe.svg"
              alt="tortilleria pepe's"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/ficorma.svg"
              alt="focorma"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/maclerk.svg"
              alt="maclerk"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/canaco.svg"
              alt="canaco"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/gn.svg"
              alt="gestion empresarial de la nueva españa"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/felipe_carrillo.svg"
              alt="instituto tecnologico de carrillo puerto"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/nomit.svg"
              alt="nomit"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/imee.svg"
              alt="imee"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/e3c.svg"
              alt="e3c"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/store.svg"
              alt="store center"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/seinqroo.svg"
              alt="seinqroo"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/becarisma.svg"
              alt="becarisma"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/adIA.svg"
              alt="adia"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/CAMPESTRE.svg"
              alt="adia"
              class="gris im"
            />
          </div>
          <div class="col-2">
            <img
              src="@/assets/imagenes/LOGOS/la-playa.svg"
              alt="adia"
              class="gris im"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ClientesComponent"
};
</script>
